import request from '@/utils/request'

// 列表数量
export function getLogListCount(params) {
  return request({
    url: '/api/content/log/list/count',
    method: 'get',
    params
  })
}

// 列表
export function getLogList(params) {
  return request({
    url: '/api/content/log/list',
    method: 'get',
    params
  })
}

