<template>
  <div class="tag-container">
    <div class="search-form">
      <el-select v-model="columnId" placeholder="全部栏目" @change="handleChangeColumn">
        <el-option label="全部栏目" value=""></el-option>
        <el-option
          v-for="item in columns"
          :key="item.columnId"
          :label="item.columnName"
          :value="item.columnId">
        </el-option>
      </el-select>
    </div>

    <el-table :data="listData" max-height="570" v-loading="loading == 'list'">
      <el-table-column type="index" :index="indexMethod" label="序号" width="100">
      </el-table-column>
      <el-table-column prop="title" label="文章名称" min-width="200" show-overflow-tooltip class-name="td-align-left">
      </el-table-column>
      <el-table-column prop="operation'" label="操作类型" width="150">
        <template slot-scope="{ row }">
          {{row.operation == 'CREATE' ? '新建' : '删除'}}
        </template>
      </el-table-column>
      <el-table-column prop="user.userName" label="操作人" width="150">
      </el-table-column>
      <el-table-column prop="createTime" label="操作时间" width="150">
        <template slot-scope="{ row }" v-if="row.createTime">
          {{ row.createTime | formatTime('{y}-{m}-{d} {h}:{i}') }}
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="listPage.total>0" :total="listPage.total" :page.sync="listPage.page" :limit.sync="listPage.pageSize" @pagination="getList" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getLogListCount, getLogList} from '@/api/log'
import { getColumnDropdownList } from '@/api/column'
import { parseTime } from '@/utils/tool.js'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      columnId: null,
      columns: [],
      listData: [],
      listPage: {
        total: 0,
        page: 1,
        pageSize : 10,
      },
      loading: false
    }
  },
  created() {
    this.getColumns()
    this.getListCount()
    this.getList()
  },
  methods: {
    getColumns() {
      getColumnDropdownList().then(res => {
        this.columns = res.data.result
      })
    },
    getListCount() {
       const params = {
        columnId: this.columnId
       }
      getLogListCount(params).then(res => {
        this.listPage.total = res.data.result
      })
    },
    getList() {
      const params = {
        columnId: this.columnId,
        page: this.listPage.page,
        pageSize: this.listPage.pageSize
      }
      this.loading = 'list'
      getLogList(params).then(res => {
        this.listData = res.data.result
        this.loading = false
      })
    },
    indexMethod(index) {
      return this.listPage.pageSize*(this.listPage.page-1)+index+1
    },
    handleChangeColumn() {
      this.listPage.page = 1
      this.getListCount()
      this.getList()
    }
  },
  filters: {
    operationFilter(status) {
      const statusMap = {
        'CREATE': 'success',
        'RECALL': 'info'
      }
      return statusMap[status]
    },
    formatStata(status) {
      const statusMap = {
        'CREATE': '已开启',
        'RECALL': '已隐藏'
      }
      return statusMap[status]
    },
    formatTime(time, format) {
      return parseTime(time, format)
    }
  }
}
</script>

<style lang="scss" >
.app-wrapper .search-form{
  padding-bottom:20px;
}
</style>
